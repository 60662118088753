
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { computed, defineComponent, onActivated, onMounted, reactive, ref, toRef } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
import XQJZDetail from '@/views/xqjz/XQJZDetail.vue'
import { formatDate, formatTime, getAreaListOfGx } from '@/utils/common'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { getJhbAccessToken, getJhbcList, QueryJhbcListParams } from '@/api/jhbc'
import JHBAdd from '@/views/jhbc/JHBAdd.vue'
import JHBDetailDialog from '@/views/jhbc/JHBDetailDialog.vue'
import { getGoodsGroupPage } from '@/api/goods'
 
export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport,
    XQJZDetail,
    JHBAdd,
    JHBDetailDialog
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<QueryJhbcListParams>({})
    const tableData = ref<AnyArray>([])
    const badgeValues = reactive<AnyObject>({})

    const selectedRowIDs = ref<number[]>([])
    const selectCount = computed(() => selectedRowIDs.value.length)
    const gxAreaList = reactive<AnyArray>([])
    
    const getTableData = async () => {
      const res = await getJhbcList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = res.data.data.records
      pagination.total = res.data.data.total
    }
    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
      getAreaListOfGx(gxAreaList) /*已在函数内部添加子项*/
    })

    const goodsGroupList = ref([])
    onActivated(() => {
      getGoodsGroupPage({ pageNo: 1, pageSize: 100 }).then(res => {
        goodsGroupList.value = res.data.data.records
      })
    })

    const onSelectionChange = (selection: any[]) => (selectedRowIDs.value = selection.map((item: AnyObject) => item.plywoodCode))

    const exportRef = ref()
    const jhbAddRef = ref()
    const jhbDetailDialogRef = ref()

    const searchInputs = reactive<Inputs>([
      {
        label: '胶合板厂名',
        type: 'input',
        placeholder: '请输入胶合板厂名',
        clearable: true,
        model: toRef(searchData, 'jhbcName')
      }, {
        label: '电话',
        type: 'input',
        placeholder: '请输入电话',
        clearable: true,
        model: toRef(searchData, 'phone')
      }, {
        label: '法人名称',
        type: 'input',
        placeholder: '请输入法人名称',
        clearable: true,
        model: toRef(searchData, 'legalName')
      }, {
        label: '区域',
        type: 'select',
        placeholder: '请选择区域',
        clearable: true,
        options: gxAreaList,
        model: toRef(searchData, 'areaCode')
      },
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '开通账号',
        icon: 'el-icon-plus',
        onClick: () => jhbAddRef.value.show()
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => {
          exportRef.value.doExport()
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        label: '多选',
        type: 'selection',
        width: '55px',
        align: 'center',
        reserveSelection: true,
      }, {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
        index: idx => pagination.pageOffset + idx
      }, {
        prop: 'name',
        label: '胶合板厂',
        minWidth: '200px',
      }, {
        prop: 'idcard',
        label: '法人身份证号',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'licenseNo',
        label: '营业执照号',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'legalName',
        label: '法人名称',
        minWidth: '200px'
      }, {
        prop: 'phone',
        label: '电话号码',
        minWidth: '200px',
        preventFormat: true
      }, {
        type: 'render',
        prop: 'createdtime',
        label: '注册时间',
        minWidth: '180px',
        render: scope => formatTime(scope.row.createdtime)
      }, {
        type: 'render',
        prop: 'contractExpireTime',
        label: '合同到期时间',
        minWidth: '180px',
        render: scope => formatTime(scope.row.contractExpireTime)
      }, {
        type: 'button',
        label: '操作',
        fixed: 'right',
        buttons: [
          {
            label: '详情',
            onClick: scope => {
              jhbDetailDialogRef.value.show(scope.row)
            }
          }, {
            label: '免密登录',
            onClick: async scope => {
              const resp = await getJhbAccessToken(scope.row.plywoodCode)
              const redirectUrl = process.env.VUE_APP_C6_REDICRCT_URL
              const respData = resp.data.data
              console.log(`${redirectUrl}#/backgroundredirect?accessToken=${respData.accessToken}&refreshToken=${respData.refreshToken}&jhbid=${scope.row.plywoodCode}`);
              window.open(`${redirectUrl}#/backgroundredirect?accessToken=${respData.accessToken}&refreshToken=${respData.refreshToken}&jhbid=${scope.row.plywoodCode}`)
            }
          }
        ]
      }
    ])

    const tableRowClassName = (scope:AnyObject) => {
      if(selectedRowIDs.value.indexOf(scope.row.plywoodCode) >= 0){
        return 'current-row' //高亮选中行，这个是 element-ui 自带CSS类名
      }else{
        return ''
      }
    }
    
    return {
      getTableData,
      onSelectionChange,
      jhbDetailDialogRef,
      jhbAddRef,
      searchInputs,
      searchButtons,
      tableColumns,
      pagination,
      badgeValues,
      tableData,
      UserRole,
      selectCount,
      exportRef,
      goodsGroupList,
      tableRowClassName
    }
  }
})
