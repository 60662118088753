
import { Buttons } from "@/types/BasicComponent";
import { defineComponent, nextTick, reactive, ref } from "@vue/runtime-core";
import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
import { addJhbcManager, AddJhbcManagerData } from "@/api/jhbc";
import commonValidate from '@/utils/validate'
import { ElMessage } from "element-plus";
import { FormRulesMap } from "element-plus/lib/components/form/src/form.type";
import dayjs from "dayjs";
import { getAreaListByPacode } from "@/api/xqjz";
import { PropType, watch } from "vue";
import useAreaCascaderProps from "@/utils/composables/useAreaCascaderProps";

export default defineComponent({
  props: {
    goodsGroupList: {
      type: Array as PropType<AnyArray>,
      default: []
    }
  },
  components: {
    BasicFormDialog
  },
  emits: ['on-success'],
  setup(props, { emit }) {
    const modalShow = ref(false)
    const show = () => {
      modalShow.value = true
    }

    const formData = reactive<AddJhbcManagerData>({
      name: '',
      phone: '',
      legalName: '',
      idcard: '',
      licenseNo: '',
      contractExpireTime: dayjs().add(1, 'years').toDate(),
      areaCode: '',
      authId: '',
    })

    // 园区级联选择
    const cascaderValue = ref([])
    watch(cascaderValue, val => {
      formData.areaCode = val[0]
      formRef.value.validateField('areaCode')
    })

    const formRules = reactive<FormRulesMap>({
      name: [
        { required: true, message: '请输入胶合板厂名' },
      ],
      phone: [
        { required: true, message: '请输入联系人手机号' },
        { type: 'number', validator: (rules, value) => commonValidate.mobile(value), message: '请输入正确的联系人手机号' }
      ],
      legalName: [
        { required: true, message: '请输入法人姓名' },
      ],
      idcard: [
        { required: true, message: '请输入法人身份证号' },
        { type: 'number', validator: (rules, value) => commonValidate.idCard(value), message: '请输入正确的法人身份证号' }
      ],
      licenseNo: [
        { required: true, message: '请输入营业执照号' },
      ],
      areaCode: [
        { required: true, message: '请选择区域' }
      ],
      goodsGroupId: [
        { required: true, message: '请选择货物分组' }
      ]
    })

    const formRef = ref()

    const footerButtons = reactive<Buttons>([
      {
        label: '确定添加',
        type: 'primary',
        onClick: async () => {
          await formRef.value.validate()
          await addJhbcManager(formData)
          ElMessage.success('添加胶合板厂管理员成功')
          emit('on-success')
          modalShow.value = false
        }
      }, {
        label: '取消',
        type: 'default',
        onClick: () => modalShow.value = false
      }
    ])

    return {
      show,
      formRules,
      formRef,
      modalShow,
      formData,
      footerButtons,
      cascaderValue,
      cascaderProps: useAreaCascaderProps()
    }
  }
})
