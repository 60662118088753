import { BaseResponse, C6BaseResponse, C6PageRequest, C6PageResponse, PageRequest } from '@/types/API'
import { SourceType } from '@/types/Common'
import request, { c6Request } from '@/utils/request'

/** 胶合板厂列表 */
export function getJhbcList(params: C6PageRequest<QueryJhbcListParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`backstage/jhb/getJhbPage`, { params })
}

/** 获取胶合板厂配置信息分页 */
export function getJhbcConfigList(params: C6PageRequest<JhbcConfigQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`backstage/jhb/getJhbConfigPage`, { params })
}

/** 更新胶合板厂配置信息 */
export function updateJhbConfig(data: UpdateJhbConfigData) {
  return c6Request.post<void>(`backstage/jhb/updateJhbConfig`, data)
}

/** 胶合板厂企业账户列表 */
export function getJhbcAccountList(params: C6PageRequest<QueryJhbcAccountListParams>) {
  return c6Request.get<C6BaseResponse>(`backstage/jhb/getJhbAccountSummaryPage`, { params })
}

/** 胶合板厂账户流水 */
export function getJhbcAccountFlow(params: C6PageRequest<QueryJhbcAccountFlowParams>) {
  return c6Request.get<C6BaseResponse>(`backstage/jhb/getJhbcAccountFlow`, { params })
}

/** 保存胶合板厂信息 */
export function updateJhbcInfo(data: JhbcInfoUpdateData) {
  return c6Request.post<C6BaseResponse<void>>(`backstage/jhb/updateJhbcInfo`, data)
}

/** 添加C6段胶合板厂管理员账号 */
export function addJhbcManager(data: AddJhbcManagerData) {
  return c6Request.post<C6BaseResponse>(`rms/jhblist/remoteSaveJhb`, data)
}

/** 获取胶合板厂端系统accesstoken */
export function getJhbAccessToken(plywoodCode: number) {
  return c6Request.get<C6BaseResponse<AccessTokenData>>(`backstage/auth/getJhbcManagerOauthToken`, { params: { plywoodCode } })
}

/** 获取胶合板厂图片 */
export function getJhbImages(jhbId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/file/getFileByType`,  { params: { sourceTypeId: SourceType.JHBC, sourceId: jhbId } })
}

export interface QueryJhbcListParams {
  jhbcName?: string,
  phone?: string,
  legalName?: string,
  areaCode?: string
}

export interface AddJhbcManagerData {
  name: string,
  phone: string,
  legalName: string,
  idcard: string,
  licenseNo: string,
  contractExpireTime: Date,
  areaCode: string,
  authId?: string,
  goodsGroupId?: number
}

export interface QueryJhbcAccountListParams {
  corpName?: string,
  createTimeBegin?: Date,
  createTimeEnd?: Date,
  invoiceChargeMode?: number,
  isTaxIncluded?: number
}

export interface QueryJhbcAccountFlowParams {
  jhbId: number,
  flowType?: number,
  createTimeBegin?: Date,
  createTimeEnd?: Date
}

export interface AccessTokenData {
  accessToken: string,
  refreshToken: string
}

export interface JhbcInfoUpdateData {
  plywoodCode?: number,
  areacode?: string,
  authid?: string,
  contractExpireTime?: Date,
  idcard?: string,
  legalName?: string,
  licenseNo?: string,
  goodsGroupId?: number
}

export interface JhbcConfigQueryParams {
  jhbcName?: string,
  areaCode?: string
}

export interface UpdateJhbConfigData {
  jcid: number,
  serviceFee: number,
  sfCalcType: number,
  sfChargeType: number,
  sfAutoPayDays: number,
  goodsGroupId: number,
}