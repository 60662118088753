
import BasicFormDialog from "@/components/BasicPageLayout/BasicFormDialog.vue";
import BasicInputGroup from '@/components/BasicPageLayout/BasicInputGroup.vue'
import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
import { Buttons, UploadFileExtend } from "@/types/BasicComponent";
import { defineComponent, PropType, reactive, ref, watch } from "vue";
import { FileType, SourceType } from "@/types/Common";
import { getFileBasePreviewUrl, deleteBindUploadedFile, BindFileInfo, uploadFile, uploadFileGetFileInfo, bindUploadedFile } from "@/api/file";
import { getJhbImages, JhbcInfoUpdateData, updateJhbcInfo } from "@/api/jhbc";
import { getAreaByAreaCode, getAreaListByPacode } from "@/api/xqjz";
import { copyObject } from "@/utils/common";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
import useAreaCascaderProps from "@/utils/composables/useAreaCascaderProps";

type PartialFileInfoWithId = Partial<UploadFileExtend> & { id: number }

export default defineComponent({
  components: {
    BasicFormDialog,
    BasicInputGroup,
    BasicImageList,
  },
  emits: ['on-success'],
  setup(props, { emit }) {
    const formData = reactive<AnyObject>({})
    // 营业执照图片
    const businessFileList = reactive<PartialFileInfoWithId[]>([])
    // 合同图片
    const contractFileList = reactive<PartialFileInfoWithId[]>([])
    const jhbId = ref(-1)

    const modalShow = ref(false)

    const show = async (row: AnyObject) => {
      modalShow.value = true

      copyObject(formData, row)
      formData.contract_Expire_Time = dayjs(formData.contract_Expire_Time).toDate()
      jhbId.value = row.plywood_Code

      cascaderValue.value = []
      formData.areaname = ''
      if (formData.areacode) {
        const areaResp = await getAreaByAreaCode(formData.areacode)
        const area = areaResp.data.data
        formData.areaname = area.areaName
      }

      loadImage()
    }

    const loadImage = async () => {
      const baseUrlResp = await getFileBasePreviewUrl()
      const baseImgUrl = baseUrlResp.data.data

      // 获取胶合板厂图片
      const imgResp = await getJhbImages(jhbId.value)
      const respFileList = imgResp.data.data as BindFileInfo[]

      businessFileList.length = 0
      contractFileList.length = 0
      respFileList.forEach((fileInfo, index) => {
        const url =  fileInfo.fileGuid? baseImgUrl + fileInfo.fileGuid: baseImgUrl.replace('userFileGuid', 'userFileId') + fileInfo.fileID
        if (fileInfo.fileTypeID === FileType.BUSINESS_LICENSE) {
          businessFileList.push({ url, id: fileInfo.id })
        } else if (fileInfo.fileTypeID === FileType.CONTRACT) {
          contractFileList.push({ url, id: fileInfo.id })
        }
      })
    }

    const onUpload = (type: number) => async (file: UploadFileExtend) => {
      let sourceTypeId = SourceType.JHBC
      let fileTypeId
      // type: 1营业执照 2合同
      if (type === 1) {
        fileTypeId = FileType.BUSINESS_LICENSE
      } else {
        fileTypeId = FileType.CONTRACT
      }

      const resp = await uploadFileGetFileInfo(file.raw)
      await bindUploadedFile({
        sourceId: jhbId.value,
        sourceTypeId,
        fileTypeId,
        fileGuid: resp.userFileGuid,
        fileId: resp.userFileId,
      })
      loadImage()
    }

    const onFileDelete = (file: AnyObject) => {
      return new Promise<void>((resolve, reject) => {
        deleteBindUploadedFile([file.id])
        resolve()
      })
    }

    // 园区级联选择
    const cascaderValue = ref([])
    watch(cascaderValue, val => {
      formData.areacode = val[0]
    })

    const footerButtons = reactive<Buttons>([
      {
        type: 'primary',
        label: '保存',
        onClick: async () => {
          const requestData: JhbcInfoUpdateData = {
            areacode: formData.areacode,
            authid: formData.authid,
            contractExpireTime: formData.contractExpireTime,
            idcard: formData.idcard,
            legalName: formData.legalName,
            licenseNo: formData.licenseNo,
            plywoodCode: formData.plywoodCode,
            goodsGroupId: formData.goodsGroupId
          }
          await updateJhbcInfo(requestData)
          ElMessage.success('保存成功')
          modalShow.value = false
          emit('on-success')
        }
      }, {
        type: 'default',
        label: '关闭',
        onClick: () => modalShow.value = false
      }
    ])

    return {
      formData,
      FileType,
      modalShow,
      businessFileList,
      contractFileList,
      footerButtons,
      cascaderValue,
      cascaderProps: useAreaCascaderProps(),
      show,
      onFileDelete,
      onUpload
    }
  }
})
