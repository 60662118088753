import { getAreaListByPacode } from "@/api/xqjz"

export default (rootAreaCode = '450000', leafLevel = 0) => {
  return {
    lazy: true,
    async lazyLoad(node: any, resolve: any) {
      const { level, value } = node
      let paCode: string
      if (level === 0) paCode = rootAreaCode
      else paCode = value
  
      const resp = await getAreaListByPacode(paCode)
      const respData = resp.data.data
      const cascaderData = respData.map((areaInfo: AnyObject) => {
        return {
          label: areaInfo.areaName,
          value: areaInfo.areaCode,
          leaf: level == leafLevel
        }
      })
      resolve(cascaderData)
    }
  }
}

