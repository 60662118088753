import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!

  return (_openBlock(), _createBlock(_component_BasicFormDialog, {
    modelValue: _ctx.modalShow,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modalShow) = $event)),
    title: "开通胶合板厂账号",
    footerButtons: _ctx.footerButtons,
    width: "1300px",
    top: "10vh",
    closeOnClickModal: true
  }, {
    body: _withCtx(() => [
      _createVNode(_component_el_form, {
        class: "dialog-form",
        model: _ctx.formData,
        ref: "formRef",
        "label-width": "160px",
        inline: true,
        rules: _ctx.formRules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            prop: "name",
            size: "small",
            label: "胶合板厂名称"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: "请输入胶合板厂",
                modelValue: _ctx.formData.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "idcard",
            size: "small",
            label: "法人身份证号"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: "请输入法人身份证号",
                modelValue: _ctx.formData.idcard,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.idcard) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "licenseNo",
            size: "small",
            label: "营业执照号"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: "请输入营业执照号",
                modelValue: _ctx.formData.licenseNo,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.licenseNo) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "legalName",
            size: "small",
            label: "法人姓名"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: "请输入法人姓名",
                modelValue: _ctx.formData.legalName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.legalName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "phone",
            size: "small",
            label: "联系人手机号"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                placeholder: "请输入联系人手机号",
                modelValue: _ctx.formData.phone,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.phone) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "contractExpireTime",
            size: "small",
            label: "合同到期时间"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_date_picker, {
                placeholder: "请选择合同到期时间",
                modelValue: _ctx.formData.contractExpireTime,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.contractExpireTime) = $event)),
                style: {"width":"200px"}
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "areaCode",
            size: "small",
            label: "地区"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_cascader, {
                modelValue: _ctx.cascaderValue,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cascaderValue) = $event)),
                props: _ctx.cascaderProps,
                "show-all-levels": false,
                style: {"width":"200px"}
              }, null, 8, ["modelValue", "props"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "goodsGroupId",
            size: "small",
            label: "货物分组"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.formData.goodsGroupId,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.goodsGroupId) = $event)),
                style: {"width":"200px"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodsGroupList, (goodsGroup) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: goodsGroup.id,
                      label: goodsGroup.name,
                      value: goodsGroup.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue", "footerButtons"]))
}